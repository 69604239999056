export default {
  common: {
    // header
    home: '首页',
    document: '文档',
    // banner
    bannerTitle: '欢迎来到星火名字服务BNS',
    bannerDesc1: 'BNS（Blockchain-based Name System）是遵照自主管理身份SSI理念，基于全球区块链基础设施星火·链网建立的新型名字服务。致力于将多种晦涩难记的计算机标识符转化为人类可识读的，具有品牌价值或个人属性的BNS名字，打破终端用户因为记忆和管理复杂地址而造成的使用壁垒。',
    more: '了解更多',
    search: '查询',
    // 创新型BNS名字空间
    namespaceTitle: '纯粹、友好、广阔的BNS名字空间',
    namespaceTitleItem1: '一个“简单、纯粹”的名字',
    namespaceDescItem1: '创新性的让名字回归名字本身，用户只需拥有“一段式”的BNS名字，即可开始使用它映射至多种地址记录。',
    namespaceTitleItem2: '对用户超级友好',
    namespaceDescItem2: '注册层面，无论个人、组织还是企业用户，均能根据意愿，自由申请自己喜欢的名字。',
    namespaceDescItem3: '管理层面，BNS名字真正让用户从名字的“使用者”变为名字的“拥有者”，只有名字的拥有者可对名字信息、映射记录、所有权等内容进行管理，任何他方无权干涉。',
    namespaceTitleItem3: '拥有广阔的拓展空间',
    namespaceDescItem4: '1、允许用户根据自己的使用需求进行子名称的拓展',
    namespaceDescItem5: '2、现有DNS域名、Web3域名、注册商标名称等现有世界中的名字进行预留保护，并欢迎对应的名字所有者来到BNS空间中进行名字的认证使用',
    // BNS应用生态
    ecologyTitle: 'BNS应用生态',
    ecologyDesc: '一个BNS名称，映射多类标识，覆盖多场景应用',
    // AIR联盟
    airTitle: 'BNS共治体系-AIR联盟',
    airDesc: '为了更好的管理与支持BNS名字服务，由多利益相关方共同组成Alliance of ID Registry(简称为 “AIR联盟”)，对BNS进行共治化管理',
    joinAir: '咨询加入AIR联盟',
    // 工作机制
    workTitle: '工作机制',
    workDescItem1: 'BNS名称申请',
    workDescItem2: 'AIR联盟共识投票',
    workDescItem3: '完成BNS名称注册',
    workDescItem4: '数据自管理',
    workDescItem5: '开启各类应用',
    // 合作公司
    partnersTitle: '合作公司',
    // footer
    footerLink: '常用链接',
    footerLinkItem1: ' 中华人民共和国工业和信息化部 ',
    footerLinkItem2: '中国信息通信研究院',
    footerLinkItem3: '工业互联网产业联盟',
    footerLinkItem4: '中国互联网协会',
    connectUs: '联系我们',
    connectUs1: ' 中国信息通信研究院 | 工业互联网与物联网研究所 ',
    address: '地址',
    zipCode: '邮编',
    phone: '电话',
    mail: '邮箱',
    caseNumber1: '中国信息通信研究院 Copyright © 2018-2023 |',
    caseNumber2: '京ICP备09013372号-137',
  },
  doc: {
    docTitle: '星火链标识管理',
    previous: '上一篇',
    next: '下一篇',
    content: '本页内容',
  }
};
