<template>
  <div class="langSelected">
    <el-dropdown @command="handleCommand">
      <span class="el-dropdown-link">
        {{ language }}<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="zh">中文</el-dropdown-item>
        <el-dropdown-item command="en">English</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
export default {
  name: 'langSelected',
  data() {
    return {
      language: '',
    };
  },
  methods: {
    // 根据下拉框的中被选中的值切换语言
    handleCommand(command) {
      if (command === 'zh') {
        this.lang = '中文';
      } else {
        this.lang = 'English';
      }
      this.$i18n.locale = command;
      sessionStorage.setItem('lang', command);
      location.reload();
    },
  },
  created() {
    this.language = this.$i18n.locale === 'zh' ? '中文' : 'English';
  },
};
</script>
<style lang="scss">
.langSelected {
  .el-dropdown-link {
    &:hover {
      cursor: pointer;
    }
  }
  .el-dropdown,
  .el-dropdown-menu__item {
    color: #fff;
  }
}
</style>
