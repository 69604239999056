import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//导入配置好的国际化语言包
import i18n from './i18n'

// 按需引入组件，以减少体积
// https://element.eleme.cn/#/zh-CN/component/quickstart
import {
  Button,
  Input,
  Descriptions,
  DescriptionsItem,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Message,
  MessageBox,
  Menu,
  MenuItem,
} from 'element-ui';
Vue.use(Button);
Vue.use(Input);
Vue.use(Descriptions);
Vue.use(DescriptionsItem);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Menu);
Vue.use(MenuItem);

Vue.prototype.$ELEMENT = { size: 'small' };
Vue.prototype.$message = Message;
Vue.prototype.$msgbox = MessageBox;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n, // 注入 配置好的国际化语言配置
  render: (h) => h(App),
}).$mount("#app");
