<template>
  <div class="banner">
    <div class="banner-center">
      <div class="banner-left">
        <div class="banner-info">
          {{ $t('common.bannerTitle') }}
          <ul>
            <li>{{ $t('common.bannerDesc1') }}</li>
          </ul>
        </div>
        <!-- 了解更多 -->
        <div class="banner-detail">
          <router-link :to="`/doc/${$i18n.locale}/index`" target="_blank">{{
            $t('common.more')
          }}</router-link>
        </div>
      </div>
      <div class="banner-right"></div>
    </div>
    <!-- 查询 -->
    <div class="banner-search">
      <input v-model="nameInput" @keyup.enter="onSearch" class="input" />
      <div class="search-btn" @click="onSearch">{{ $t('common.search') }}</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      nameInput: '',
    };
  },
  methods: {
    onSearch() {
      if (this.nameInput) {
        let name = this.nameInput;
        window.open(`http://192.168.11.35:3000/#/name/${name}`);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.banner {
  width: 100%;
  min-width: 1200px;
  height: 450px;
  background: #284570;

  .banner-center {
    display: flex;
    width: 1120px;
    height: 300px;
    padding: 0 40px;
    margin: 0 auto;
    justify-content: space-between;

    .banner-info {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #fcfafa;
      line-height: 28px;
      margin-top: 60px;
      max-width: 500px;
      ul {
        li {
          text-indent: 20px;
        }
      }
    }

    .banner-detail {
      width: 102px;
      height: 32px;
      display: inline-block;
      border-radius: 2px;
      cursor: pointer;
      margin-top: 24px;
      text-align: center;
      line-height: 32px;
      border: 1px solid #fff;
      a {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #fcfafa;
      }
    }

    .banner-right {
      background: url('../assets/images/home_1.png') no-repeat center;
      background-size: contain;
      height: 100%;
      width: 560px;
    }
  }

  .banner-search {
    width: 70%;
    margin: 30px auto;
    display: flex;
    justify-content: center;
    .input {
      box-sizing: border-box;
      width: 800px;
      height: 46px;
      line-height: 46px;
      font-size: 20px;
      padding: 20px;
      border: none;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      outline: none;
    }
    .search-btn {
      width: 80px;
      height: 46px;
      background: #3381ea;
      color: #fff;
      text-align: center;
      line-height: 46px;
      cursor: pointer;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
}
</style>
