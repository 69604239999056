<template>
  <div class="header">
    <!-- 左边logo -->
    <div class="header-logo">
      <div class="header-logo-img">
        <img :src="require('../assets/images/logo.png')" alt="" />
      </div>
    </div>
    <!-- 右侧菜单栏 -->
    <ul class="header-menu">
      <li class="headerActive">
        <a>{{ $t('common.home') }}</a>
      </li>
      <!-- <li>
        <router-link :to="`/doc/${$i18n.locale}/index`" target="_blank">
          {{ $t('common.document') }}
        </router-link>
      </li>  -->
      <li>
        <el-dropdown @command="handleCommand" trigger="click">
          <span class="el-dropdown-link" style="color:#fff">
            注册<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="bl">
              <a href="https://app-bns.bitfactory.cn" target="_blank" style="color:#3381ea">注册入口-北京</a>
            </el-dropdown-item>
            <el-dropdown-item command="ms">
              <a href="https://xinghuobns.zetrix.com" target="_blank" style="color:#3381ea">注册入口-马来西亚</a>
            </el-dropdown-item>
            <el-dropdown-item command="am" disabled>注册入口-中国澳门</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </li>
    </ul>
    <langSelected v-if="false" class="lang" />
  </div>
</template>
<script>
import langSelected from '../components/langSelected.vue';
export default {
  components: { langSelected },
  data() {
    return {
      active: 0,
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.header {
  width: 100%;
  min-width: 1200px;
  height: 70px;
  position: sticky;
  top: 0;
  z-index: 999;
  background: #000000;

  .header-logo {
    height: 100%;
    margin-left: 48px;
    cursor: pointer;
    float: left;

    .header-logo-img {
      width: 128px;
      height: 30px;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin-left: 48px;
        margin-top: 18px;
      }
    }
  }

  .header-menu {
    float: right;
    margin-right: 120px;
    height: 100%;

    li {
      float: left;
      height: 100%;
      cursor: pointer;
      line-height: 70px;
      padding: 0 27px;
      &.headerActive {
        background: #3381ea !important;
      }
      a {
        display: block;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #fcfafa;
      }
    }
  }

  .lang {
    position: absolute;
    top: 24px;
    right: 30px;
  }
}
</style>