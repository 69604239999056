<template>
  <div class="footer">
    <div class="footer-center">
      <!-- logo -->
      <div class="footer-logo"></div>
      <!-- 常用链接 -->
      <div class="footer-link">
        <h3>{{ $t('common.footerLink') }}</h3>
        <ul class="footer-link-list">
          <li @click="change('http://www.miit.gov.cn/')">
            {{ $t('common.footerLinkItem1') }}
          </li>
          <li @click="change('http://www.caict.ac.cn/')">
            {{ $t('common.footerLinkItem2') }}
          </li>
          <li @click="change('http://www.aii-alliance.org/')">
            {{ $t('common.footerLinkItem3') }}
          </li>
          <li @click="change('https://www.isc.org.cn/')">
            {{ $t('common.footerLinkItem4') }}
          </li>
        </ul>
      </div>
      <!-- 联系我们 -->
      <div class="footer-link">
        <h3>{{ $t('common.connectUs') }}</h3>
        <ul class="footer-link-list">
          <li>{{ $t('common.connectUs1') }}</li>
          <li>{{ $t('common.address') }}：北京市海淀区花园路52号</li>
          <li>{{ $t('common.zipCode') }}：100191</li>
          <li>{{ $t('common.phone') }}：+86-10-58846917</li>
          <li>{{ $t('common.mail') }}：bns@caict.ac.cn</li>
        </ul>
      </div>
      <!-- 二维码 -->
      <div class="footer-code">
        <img src="../assets/images/code1.png" alt="" />
        <img src="../assets/images/code2.png" alt="" />
      </div>
    </div>
    <!-- 京备号 -->
    <div class="footer-num">
      {{ $t('common.caseNumber1') }}
      <span
        style="cursor: pointer"
        @click="change('https://beian.miit.gov.cn/')"
        >{{ $t('common.caseNumber2') }}</span
      >
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    change(url) {
      window.open(url);
    },
  },
};
</script>
<style scoped>
.footer {
  width: 100%;
  min-width: 1200px;
  background: #0b182e;
  padding-top: 55px;
}
.footer-center {
  width: 1200px;
  margin: 0 auto;
  overflow: hidden;
}
.footer-logo {
  width: 128px;
  height: 30px;
  background: url('../assets/images/logo.png') no-repeat center;
  background-size: cover;
  float: left;
  overflow: hidden;
}
.footer-link {
  float: left;
  margin-left: 80px;
  overflow: hidden;
}
.footer-link h3 {
  font-size: 16px;
  color: #fff;
  font-weight: bold;
}
.footer-link-list {
  margin-top: 15px;
}
.footer-link-list li {
  font-size: 14px;
  color: #858b96;
  margin-top: 10px;
  cursor: pointer;
}
.footer-code {
  float: right;
  overflow: hidden;
}
.footer-code img {
  display: block;
  width: 122px;
  height: 122px;
  float: left;
}
.footer-code img:first-child {
  margin-right: 60px;
}
.footer-num {
  width: 100%;
  margin-top: 100px;
  border-top: 1px solid #232f42;
  font-size: 14px;
  line-height: 42px;
  color: #9ea4ad;
  text-align: center;
}
</style>
