import Vue from "vue";
import VueRouter from "vue-router";
// import Index from "../views/Index.vue";
// import Index from "../views/Home.vue";
import Index from "../views/newHome.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: '/home',
  },
  {
    path: "/home",
    name: "Index",
    component: Index,
  },
  {
    path: "/doc/:lang/:nav",
    name: "Doc",
    component: () =>
      import(/* webpackChunkName: "doc" */ "../views/doc/Index.vue"),
  },
  // {
  //   path: "/home",
  //   name: "Home",
  //   // route level code-splitting
  //   // this generates a separate chunk (home.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  // },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
  // {
  //   path: "/search/:name",
  //   name: "Search",
  //   component: () =>
  //     import(/* webpackChunkName: "search" */ "../views/Search.vue"),
  // },
];

const router = new VueRouter({
  routes,
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }), //滚动到顶端
});

export default router;
