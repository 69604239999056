<template>
  <div class="home">
    <!-- 头部 -->
    <Header></Header>
    <!-- banner图 -->
    <Banner v-if="false"></Banner>

    <!-- 内容 -->
    <div class="home-center" v-if="false">
      <!-- 创新型BNS名字空间 -->
      <div class="namespace" id="namespace">
        <h3 class="common-title">{{ $t('common.namespaceTitle') }}</h3>
        <ul class="namespace-box">
          <li
            class="namespace-card"
            v-for="(item, index) in namespaceList"
            :key="index"
          >
            <div class="namespace-card-top">
              <div class="title">{{ item.label }}</div>
              <div class="common-desc" v-if="typeof item.describe === 'string'">
                {{ item.describe }}
              </div>
              <div v-else>
                <div
                  class="common-desc"
                  v-for="des in item.describe"
                  :key="des"
                >
                  {{ des }}
                </div>
              </div>
            </div>
            <img :src="require(`@/assets/home/namespace${index + 1}.png`)" />
          </li>
        </ul>
      </div>
      <!--BNS应用生态-->
      <div class="bns-ecology" id="bns-ecology">
        <h3 class="common-title">{{ $t('common.ecologyTitle') }}</h3>
        <div class="common-desc">
          {{ $t('common.ecologyDesc') }}
        </div>
        <div class="bns-ecology-box">
          <img src="~@/assets/home/bnsEcology.png" width="100%" alt="" />
        </div>
      </div>
      <!-- AIR -->
      <div class="air-alliance" id="air-alliance">
        <h3 class="common-title">{{ $t('common.airTitle') }}</h3>
        <div class="common-desc">
          {{ $t('common.airDesc') }}
        </div>
        <div class="air-alliance-box">
          <img src="~@/assets/home/air.png" alt="" />
          <el-button class="join" type="text" @click="open">{{
            $t('common.joinAir')
          }}</el-button>
        </div>
      </div>
      <!-- 工作机制 -->
      <div class="work-mechanism" id="work-mechanism">
        <h3 class="common-title">{{ $t('common.workTitle') }}</h3>
        <div class="work-mechanism-box">
          <img src="~@/assets/images/work.png" alt="" width="80%" />
          <div class="item1">{{ $t('common.workDescItem1') }}</div>
          <div class="item2">{{ $t('common.workDescItem2') }}</div>
          <div class="item3">{{ $t('common.workDescItem3') }}</div>
          <div class="item4">{{ $t('common.workDescItem4') }}</div>
          <div class="item5">{{ $t('common.workDescItem5') }}</div>
        </div>
      </div>

      <!-- 合作公司 -->
      <div class="partners" id="partners">
        <h3 class="common-title">{{ $t('common.partnersTitle') }}</h3>
        <div class="partners-box swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <a href="#" target="_blank">
                <img src="~@/assets/home/partners1.png" alt="" />
              </a>
            </div>
            <div class="swiper-slide">
              <a href="#" target="_blank">
                <img src="~@/assets/home/partners2.png" alt="" />
              </a>
            </div>
            <div class="swiper-slide">
              <a href="#" target="_blank">
                <img src="~@/assets/home/partners1.png" alt="" />
              </a>
            </div>
            <div class="swiper-slide">
              <a href="#" target="_blank">
                <img src="~@/assets/home/partners2.png" alt="" />
              </a>
            </div>
            <div class="swiper-slide">
              <a href="#" target="_blank">
                <img src="~@/assets/home/partners1.png" alt="" />
              </a>
            </div>
            <div class="swiper-slide">
              <a href="#" target="_blank">
                <img src="~@/assets/home/partners2.png" alt="" />
              </a>
            </div>
          </div>

          <!-- 如果需要滚动条 -->
          <!-- <div class="swiper-scrollbar"></div> -->
        </div>
      </div>
    </div>

    <!-- 内容 -->
    <div class="newHome-center">
      <div class="content1">
        <img src="~@/assets/newHome/content1.jpg" alt="" />
        <div class="download" @click="download('/download/BNS%20White%20Paper.pdf')">
          <img src="~@/assets/newHome/download.png" alt="" />
        </div>
        <div class="download2" @click="download('/download/BNS%20User%20Guide.pdf')">
          <img src="~@/assets/newHome/download1.png" alt="" />
        </div>
      </div>
      <img src="~@/assets/newHome/content2.jpg" alt="" />
      <img src="~@/assets/newHome/content3.jpg" alt="" />
      <img src="~@/assets/newHome/content4.jpg" alt="" />
      <img src="~@/assets/newHome/content5.jpg" alt="" />
      <img src="~@/assets/newHome/content6.jpg" alt="" />
      <img src="~@/assets/newHome/content7.jpg" alt="" />
    </div>

    <!-- 底部 -->
    <Footer></Footer>
    <!-- 滚动按钮 -->
    <div class="scroll-top" @click="goPost" v-show="showIcon"></div>
  </div>
</template>

<script>
import Header from '../components/Header.vue';
import Banner from '../components/Banner.vue';
import Footer from '../components/Footer.vue';

import 'swiper/dist/js/swiper';
import 'swiper/dist/css/swiper.css';
import Swiper from 'swiper';

export default {
  components: {
    Header,
    Banner,
    Footer,
  },
  data() {
    return {
      namespaceList: [
        {
          label: this.$t('common.namespaceTitleItem1'),
          describe: this.$t('common.namespaceDescItem1'),
        },
        {
          label: this.$t('common.namespaceTitleItem2'),
          describe: [
            this.$t('common.namespaceDescItem2'),
            this.$t('common.namespaceDescItem3'),
          ],
        },
        {
          label: this.$t('common.namespaceTitleItem3'),
          describe: [
            this.$t('common.namespaceDescItem4'),
            this.$t('common.namespaceDescItem5'),
          ],
        },
      ],
      showIcon: false, //图标默认隐藏
      scrollTop: '',
    };
  },
  mounted() {
    window.addEventListener('scroll', this.scrollBackTop); //监听滚动条
    // 轮播图
    new Swiper('.swiper-container', {
      autoplay: {
        delay: 3000,
        disableOnInteraction: false, //*手动操作轮播图后不会暂停*
      },
      slidesPerView: 3,
      spaceBetween: 30,
      // loop: true, // 循环模式选项

      // 如果需要滚动条
      scrollbar: {
        el: '.swiper-scrollbar',
      },
    });
  },
  watch: {},
  created() {},
  methods: {
    download(url) {
      window.open(url);
    },
    // 点击成为AIR联盟成员
    open() {
      const h = this.$createElement;
      this.$msgbox({
        title: '成为AIR联盟成员',
        message: h('ul', null, [
          h(
            'li',
            { style: 'text-align: center', class: 'msgboxLi' },
            '这里是对成为AIR联盟成员资质的要求'
          ),
          h('li', { class: 'msgboxLi' }, `联系电话：021-588232421`),
          h('li', { class: 'msgboxLi' }, `联系地址：北京市海淀区花园路52号`),
          h('li', { class: 'msgboxLi' }, `联系邮箱：contact@caict.com`),
        ]),
        showConfirmButton: false,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = '执行中...';
            setTimeout(() => {
              done();
              setTimeout(() => {
                instance.confirmButtonLoading = false;
              }, 300);
            }, 3000);
          } else {
            done();
          }
        },
      });
    },
    // 滚动超过60图标显示
    scrollBackTop() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.scrollTop = scrollTop;
      if (this.scrollTop > 60) {
        this.showIcon = true;
      } else {
        this.showIcon = false;
      }
    },
    // 滚动到页面顶部
    goPost() {
      let height =
        document.documentElement.scrollTop || document.body.scrollTop; //获得当前高度
      let step = height / 30; //每步的距离
      (function jump() {
        if (height > 0) {
          height -= step;
          window.scrollTo(0, height);
          setTimeout(jump, 10);
        }
      })();
    },
  },
  //移除滚动条监听
  destroyed() {
    window.removeEventListener('scroll', this.scrollBackTop);
  },
};
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
a {
  text-decoration: none;
}
.common-title {
  padding: 5px 0 20px 20px;
  // background: url('../assets/images/title-icon.png') no-repeat center;
  background-position: 46% 0;
  background-size: 48px 48px;
  font-size: 30px;
  font-weight: 500;
  color: #000;
  text-align: center;
}
.common-desc {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  margin-bottom: 30px;
  text-align: center;
}
.home {
  background: #fafafa;
  min-width: 1200px;
  // overflow: hidden;
  .home-center {
    // BNS名字空间
    .namespace {
      overflow: hidden;
      padding: 40px 0;
      background: #fff;

      .namespace-box {
        width: 1120px;
        margin: 0 auto;

        .namespace-card {
          margin-bottom: 20px;
          text-align: center;
          .namespace-card-top {
            .title {
              padding: 20px;
              font-size: 24px;
              font-weight: 500;
              color: #333;
            }
            .common-desc {
              margin-bottom: 10px;
            }
          }
          img {
            margin-top: 10px;
            width: 80%;
          }
        }
      }
    }
    // BNS应用生态
    .bns-ecology {
      overflow: hidden;
      background: #284570;
      padding: 40px 0;
      .common-title,
      .common-desc {
        color: #fff !important;
      }
      .bns-ecology-box {
        width: 1120px;
        margin: 0 auto;
        position: relative;
        text-align: center;
      }
    }
    // AIR ( Alliance of ID Registry ) 联盟
    .air-alliance {
      overflow: hidden;
      background: #fff;
      padding: 40px 0;
      padding: 40px 0 100px;
      .air-alliance-box {
        width: 1120px;
        margin: 0 auto;
        position: relative;
        text-align: center;
        .join {
          position: absolute;
          right: 0;
        }
      }
    }
    // 工作机制
    .work-mechanism {
      overflow: hidden;
      background: #fff;
      padding: 40px 0;
      .work-mechanism-box {
        width: 1120px;
        margin: 50px auto;
        position: relative;
        text-align: center;
        & > div {
          position: absolute;
          font-size: 18px;
          &.item1 {
            bottom: -30px;
            left: 200px;
          }
          &.item2 {
            top: -40px;
            left: 33%;
          }
          &.item3 {
            bottom: -30px;
            left: 48%;
          }
          &.item4 {
            top: -40px;
            right: 26%;
          }
          &.item5 {
            bottom: -30px;
            right: 99px;
          }
        }
      }
    }

    // 合作伙伴
    .partners {
      overflow: hidden;
      background: #f6f7fb;
      padding: 40px 0;

      .partners-box {
        margin: 0 auto;
        width: 1120px;
        height: 120px;

        .swiper-slide {
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .swiper-slide img {
          display: block;
          width: 290px;
        }
      }
    }
  }

  .newHome-center {
    overflow: hidden;
    img {
      max-width: 100%;
      float: left;
    }
    .content1 {
      overflow: hidden;
      position: relative;
      .download {
        position: absolute;
        top: 57.2%;
        left: 17.73%;
        width: 15%;
        z-index: 2;
        cursor: pointer;
      }
      .download2 {
        position: absolute;
        top: 57.2%;
        left: 32.73%;
        width: 15%;
        z-index: 2;
        cursor: pointer;
      }
    }
  }
}

.msgboxLi {
  line-height: 34px;
}

.scroll-top {
  position: fixed;
  width: 53px;
  height: 53px;
  right: 30px;
  bottom: 30px;
  cursor: pointer;
  background: url('../assets/images/top.png') no-repeat center;
  background-size: cover;
}
</style>
