<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/home">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
  created() {
    this.checkLogin();
  },
  methods: {
    checkLogin() {
      if (sessionStorage.getItem("bns_login_bid")) {
        this.$store.commit("loginModule/setLoginStatus", {
          isLogin: true,
        });
      }
    },
  },
};
</script>

<style lang="scss">
body {
  margin: 0px;
  box-sizing: border-box;
  height: 100vh;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  height: 100%;
}

// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
