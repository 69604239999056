export default {
  namespaced: true,
  state: {
    isLogin: false
  },
  mutations: {
    setLoginStatus(state, payload) {
      state.isLogin = payload.isLogin;
    }
  },
  actions: {},
};
